.products-wrapper {
  margin-bottom: "40px";
  padding: "30px";
}

.table-container {
  background: #fff;
}

button:disabled {
  cursor: not-allowed;
}

.input-amt {
  max-width: 50px;
  margin-right: 20px;
}
