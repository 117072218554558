a.navlink {
  position: relative;
  color: #fff;
  text-decoration: none;
  margin-right: 3vw;
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
}
a.navlink::after {
  content: "";
  width: 0;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.15s cubic-bezier(0.42, 0.01, 0.01, 0.87);
}
a.navlink.active::after,
a.navlink:hover::after {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.42, 0.01, 0.01, 0.87);
}
