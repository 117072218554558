.login-root {
  height: 100vh;
}

.login-container {
  background: rgba(255, 255, 255, 0.85);
  height: 100vh;
  max-width: 100%;
  padding: 0 35px;
}

.login-form {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-bg {
  background-image: url("../img/order_login_mobile.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

@media (min-width: 767px) {
  .login-bg {
    background-image: url("../img/order_login.jpg");
  }

  .login-container {
    background: rgba(255, 255, 255, 0.9);
    max-width: 450px;
    position: relative;
    left: 0;
    top: 0;
  }
}
