* {
  font-family: "Roboto Condensed";
}

html {
  width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: #fafafa;
  color: #333;
  margin: 0;
}

.nav-item a.active {
  font-weight: bold;
}
.nav-item {
  margin: 0 15px;
}
